<template>
    <div class="tab-pane fade show active" id="nav-user-info" role="tabpanel" aria-labelledby="nav-user-info-tab">
        <div class="step-one-content">
            <form class="default-form-style" @submit.prevent="updateCategory">
                <div class="row">
                    <div class="col-12 row">
                        <div class="form-group col-5">
                            <label>Imagen de la categoría</label>
                            <div class="img-preview" v-if="imagePreview">
                                <img :src="imagePreview" alt="Vista previa de la imagen">
                            </div>
                            <div class="img-preview" v-else>
                                <span>Selecciona una imagen</span>
                            </div>
                            <input ref="categoryImage" type="file" @change="handleFileUpload" class="file-input">
                        </div>
                        <div class="col-7 column">
                            <div class="form-group">
                                <label>Nombre categoría</label>
                                <input v-model="categoryName" type="text" placeholder="Enter Title" required>
                            </div>
                            <div class="form-group">
                                <label>Url de la categoría</label>
                                <input v-model="categoryUrl" type="text" placeholder="Enter URL" required>
                            </div>
                            <div class="form-group">
                                <label>Precio mínimo</label>
                                <input v-model="categoryPrice" type="number" placeholder="Enter Price" required>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Descripción</label>
                            <textarea v-model="categoryDescription" placeholder="Enter Description"></textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group button mb-0">
                            <button type="submit" class="btn">Actualizar Categoría</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'EditarCategoria',
    props: {
        categoryId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            categoryName: '',
            categoryUrl: '',
            categoryImage: '',
            categoryDescription: '',
            categoryPrice: '',
            imagePreview: null,
            // status: '',
            // publico: ''
        }
    },
    methods: {
        handleFileUpload(event) {
            this.categoryImage = event.target.files[0];
            this.imagePreview = URL.createObjectURL(this.categoryImage);
        },
        async fetchCategory() {
            console.log(process.env.VUE_APP_PUBLIC_IMG)
            try {
                const response = await axios.get(`/api/categoria/find/${this.categoryId}`);
                const category = response.data;
                this.categoryName = category.NameCategory;
                this.categoryUrl = category.urlCategories;
                this.categoryImage = category.urlImg;
                this.imagePreview = category.urlImg ? `${process.env.VUE_APP_PUBLIC_IMG}/public/images/categories` + category.urlImg : null;
                this.categoryDescription = category.DescriptionCategory;
                this.categoryPrice = category.minPrices;
                // this.status = category.status;
                // this.publico = category.publico;
            } catch (error) {
                console.error('Error fetching category:', error);
                alert('Error al cargar la categoría');
            }
        },
        async updateCategory() {
            try {
                let imageUrl = this.categoryImage;
                if (this.categoryImage instanceof File) {
                    // Subir imagen al servidor del subdominio si se ha seleccionado una nueva imagen
                    let formData = new FormData();
                    formData.append('image', this.categoryImage);

                    const imageResponse = await axios.post('/api/upload-image/categories', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    imageUrl = imageResponse.data.imageUrl;
                }

                await axios.put(`/api/categoria/update/${this.categoryId}`, {
                    NameCategory: this.categoryName,
                    urlCategories: this.categoryUrl,
                    urlImg: "/" + imageUrl,
                    DescriptionCategory: this.categoryDescription,
                    minPrices: this.categoryPrice
                    // status: this.status,
                    // publico: this.publico
                });
                alert('Categoría actualizada exitosamente!');
            } catch (error) {
                console.error('Error updating category:', error);
                alert('Error al actualizar la categoría');
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchCategory();
        });
    },
    watch: {
        categoryId: {
            handler() {
                this.fetchCategory();
            },
            immediate: true
        }
    }
}
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}

.img-preview {
    width: 100%;
    min-height: 210px;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    text-align: center;
}

.img-preview img {
    max-width: 100%;
    max-height: 210px;
}

.file-input {
    border: none;
    padding: 0;
}
</style>
