<template>
    <div class="tab-pane fade show" id="nav-item-details" role="tabpanel" aria-labelledby="nav-item-details-tab">
        <div class="invoice-items default-list-style mt-3">
            <div v-if="message" :class="messageClass">{{ message }}</div>
            <nav class="list-nav">
                <ul class="default-form-style">
                    <li>
                        <div class="selector-head form-group mb-0 mt-0">
                            <select class="user-chosen-select" v-model="selectedCategory" @change="filterServices">
                                <option value="all">Todas las categorías</option>
                                <option v-for="category in categories" :key="category.CategoryID"
                                    :value="category.CategoryID">
                                    {{ category.NameCategory }}
                                </option>
                            </select>
                        </div>
                    </li>
                </ul>
            </nav>

            <div class="default-list-title">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-md-2 col-12">
                        <p>Nombre</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                        <p>Url</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                        <p>Categoría</p>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                        <p>Descripción</p>
                    </div>
                    <!-- <div class="col-lg-1 col-md-1 col-12">
                        <p>Status</p>
                    </div>-->
                    <div class="col-lg-1 col-md-1 col-12">
                        <p>stock</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col-12 align-left">
                        <p>Action</p>
                    </div>
                </div>
            </div>

            <div v-for="service in filteredServices" :key="service.id" class="single-list">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-md-2 col-12">
                        <p>{{ service.NameProducts }}
                            <span>$ {{ service.Price }}</span>
                        </p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                        <p>{{ service.urlProducts }}</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                        <p>{{ getCategoryName(service.CategoryID) }}</p>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                        <p>{{ service.DescriptionProducts }}</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col-12">
                        <p>{{ service.Stock }}</p>
                    </div>
                    <!-- <div class="col-lg-1 col-md-1 col-12">
                        <p :class="{ 'paid': service.status === 1, 'unpaid': service.status !== 1 }">
                            {{ service.status === 1 ? 'Activo' : 'Inactivo' }}
                        </p>
                    </div> -->
                    <!-- <div class="col-lg-1 col-md-1 col-12">
                        <p :class="{ 'paid': service.publico === 1, 'unpaid': service.publico !== 1 }">
                            {{ service.publico === 1 ? 'Activo' : 'Inactivo' }}
                        </p>
                    </div> -->
                    <div class="col-lg-1 col-md-1 col-12 align-left">
                        <ul class="action-btn">
                            <li><a href="javascript:void(0)" @click="editService(service.ProductID)"><i
                                        class="lni lni-highlight"></i></a></li>
                            <li><a href="javascript:void(0)" @click="deleteService(service.ProductID)"><i
                                        class="lni lni-trash"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'verView',
    data() {
        return {
            services: [],
            categories: [],
            filteredServices: [],
            selectedCategory: 'all'
        };
    },
    created() {
        this.fetchServices();
        this.fetchCategories();
    },
    methods: {
        async fetchServices() {
            try {
                const response = await axios.get('/api/servicio/all');
                this.services = response.data;
                this.filterServices();
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        },
        async fetchCategories() {
            try {
                const response = await axios.get('/api/categoria/all');
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        filterServices() {
            if (this.selectedCategory === 'all') {
                this.filteredServices = this.services;
            } else {
                this.filteredServices = this.services.filter(service => service.CategoryID === parseInt(this.selectedCategory));
            }
        },
        getCategoryName(categoriaId) {
            const category = this.categories.find(cat => cat.CategoryID === categoriaId);
            return category ? category.NameCategory : 'Desconocido';
        },
        editService(id) {
            this.$emit('edit-service', id);
        },
        async deleteService(id) {
            try {
                await axios.delete(`/api/servicio/delete/${id}`);
                this.fetchServices();
                this.message = 'Servicio actualizado exitosamente!';
                this.messageClass = 'alert alert-success';
            } catch (error) {
                console.error('Error deleting service:', error);
                alert('Error al eliminar el servicio');
            }
        }
    }
};
</script>

<style scoped>
.default-list-title,
.single-list {
    margin-top: 0px;
}

.single-list:hover {
    background-color: aliceblue;
}

.default-list-title p,
.single-list p {
    margin: 0;
}

.action-btn {
    list-style: none;
    padding: 0;
}

.action-btn li {

    margin-right: 9px;
    width: fit-content;
}
</style>
