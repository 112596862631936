<template>
    <div class="tab-pane fade active show" id="nav-item-info" role="tabpanel" aria-labelledby="nav-item-info-tab">
        <div v-if="message" :class="messageClass">{{ message }}</div>
        <div class="step-one-content">
            <form class="default-form-style" @submit.prevent="createService">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>Nombre del producto</label>
                            <input v-model="serviceName" type="text" placeholder="Enter nombre" required>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Descripción del producto</label>
                            <textarea v-model="serviceDescription" placeholder="Enter Description"></textarea>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Precio del producto</label>
                            <input v-model="servicePrice" type="number" placeholder="Enter Price" required>
                        </div>
                        <div class="form-group col-6">
                            <label>URL del prodcuto</label>
                            <input v-model="serviceUrl" type="text" placeholder="Enter URL" required>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Categoría del producto</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="serviceCategory" class="user-chosen-select" required>
                                    <option value="" disabled selected>Selecciona una categoría</option>
                                    <option v-for="category in categories" :key="category.CategoryID"
                                        :value="category.CategoryID">
                                        {{ category.NameCategory }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label>Stock del producto</label>
                            <input v-model="Stock" type="number" placeholder="Enter stock" required>
                        </div>
                    </div>
                    <!-- <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Visibilidad del Servicio</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="servicePublico" class="user-chosen-select" required>
                                    <option value="1">Público</option>
                                    <option value="0">Oculto</option>
                                </select>
                            </div>
                        </div>
                         <div class="form-group col-6">
                            <label>Imagen del Servicio</label>
                            <input v-model="serviceImage" type="text" placeholder="Enter Image URL">
                        </div> 
                    </div> -->
                    <div class="col-12">
                        <div class="form-group button mb-0">
                            <button type="submit" class="btn">Crear Producto</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CrearServicios',
    data() {
        return {
            serviceName: '',
            serviceDescription: '',
            servicePrice: '',
            serviceUrl: '',
            serviceCategory: '',
            Stock: '',

            categories: []
        }
    },
    created() {
        this.fetchCategories();
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await axios.get('/api/categoria/all');
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async createService() {
            try {
                await axios.post('/api/servicio/create', {
                    NameProducts: this.serviceName,
                    DescriptionProducts: this.serviceDescription,
                    Price: this.servicePrice,
                    Stock: this.Stock,
                    urlProducts: this.serviceUrl,
                    CategoryID: this.serviceCategory,

                    img: null
                });
                this.message = 'Servicio creado exitosamente!';
                this.messageClass = 'alert alert-success';

                this.resetForm();
            } catch (error) {
                console.error(error);
                this.message = 'Error al crear el servicio';
                this.messageClass = 'alert alert-danger';
            }
        },
        resetForm() {
            this.serviceName = '';
            this.serviceDescription = '';
            this.servicePrice = '';
            this.stock = '';
            this.serviceUrl = '';
            this.serviceCategory = '';
        }
    }
}
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}
</style>