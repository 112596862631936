<template>
  <div class="col-lg-10 col-md-8 col-12 scroll-vh d-flex justify-content-center pl-0">
    <div class="main-content mt-5">

      <router-view />

    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentView',
  components: {

  }
}
</script>

<style scoped>
.scroll-vh {
  height: 100vh;
  overflow-y: scroll;
  /* Ajustar según la altura del header */
}

.main-content {
  width: 80%;

}
</style>