<template>
    <div class="tab-pane fade active show" id="nav-item-info" role="tabpanel" aria-labelledby="nav-item-info-tab">
        <div v-if="message" :class="messageClass">{{ message }}</div>
        <div class="step-one-content">
            <form class="default-form-style" @submit.prevent="createContenidoServicio">
                <div class="row">

                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Categoría</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="selectedCategory" class="user-chosen-select" @change="filterServicios"
                                    required>
                                    <option value="" disabled selected>Selecciona una categoría</option>
                                    <option value="all">Todas las categorías</option>
                                    <option v-for="category in categories" :key="category.CategoryID"
                                        :value="category.CategoryID">
                                        {{ category.NameCategory }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label>Productos</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="selectedProduct" class="user-chosen-select" required
                                    @change="filterContenido">
                                    <option value="" disabled selected>Selecciona un Producto</option>
                                    <option v-for="servicio in filteredServicios" :key="servicio.ProductID"
                                        :value="servicio.ProductID">
                                        {{ servicio.NameProducts }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 row">
                            <div class="form-group col-5">
                                <label>Imagen del producto</label>
                                <div class="img-preview" v-if="imagePreview">
                                    <img :src="imagePreview" alt="Vista previa de la imagen">
                                </div>
                                <div class="img-preview" v-else>
                                    <span>Selecciona una imagen</span>
                                </div>
                                <input ref="productImages" type="file" @change="handleFileUpload" class="file-input"
                                    multiple>
                                <div class="image-list">
                                    <div v-for="(image, index) in images" :key="index" class="image-item">
                                        <img :src="image.preview" :alt="'Imagen ' + (index + 1)"
                                            @click="setPreviewImage(index)">
                                        <a @click="removeImage(index)">Eliminar</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 column">

                                <div class="form-group">
                                    <label>Nombre del producto</label>
                                    <input v-model="selectedProductDetails.NameProducts" type="text"
                                        placeholder="Enter Title" required disabled>
                                </div>

                                <div class="form-group">
                                    <label>Url del producto</label>
                                    <input v-model="selectedProductDetails.urlProducts" type="text"
                                        placeholder="Enter URL" required disabled>
                                </div>
                                <div class="form-group">
                                    <label>Precio minimo</label>
                                    <input v-model="selectedProductDetails.Price" type="number"
                                        placeholder="Enter Price" required disabled>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label>Descripción</label>
                                <textarea v-model="selectedProductDetails.DescriptionProducts"
                                    placeholder="Enter Description" disabled></textarea>
                            </div>
                        </div>

                        <h4>Caracteristicas del producto</h4>
                        <div class="col-12 row">
                            <div class="col-12 attribute-container">
                                <div class="col-6" v-for="(field, index) in attributeFields" :key="index">
                                    <div class="form-group col-12">
                                        <label>{{ field.label }}</label>
                                        <div class="attribute-field">
                                            <input v-model="field.value" :type="field.type"
                                                :placeholder="field.placeholder" required>
                                            <a @click="removeAttributeField(index)">Eliminar</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 form-group">
                                    <label>Agregar Atributo</label>
                                    <div class="attribute-input">
                                        <input v-model="newAttributeName" type="text" placeholder="Nombre del atributo">
                                        <input v-model="newAttributeValue" type="text" placeholder="Valor del atributo">
                                        <a @click="addAttributeField">+</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group button mb-0">
                                <button type="submit" class="btn">Crear contenido del producto</button>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CrearContenidoServicio',
    data() {
        return {
            images: [],
            imagePreview: null,
            selectedProduct: '',
            servicios: [],
            filteredServicios: [],
            categories: [],
            selectedProductDetails: {},
            selectedCategory: 'all',
            message: '',
            messageClass: '',
            newAttributeName: '',
            newAttributeValue: '',
            attributeFields: []
        }
    },
    created() {
        this.fetchCategorias();
        this.fetchServicios();
    },
    methods: {
        addAttributeField() {
            if (this.newAttributeName && this.newAttributeValue) {
                this.attributeFields.push({
                    label: this.newAttributeName,
                    value: this.newAttributeValue,
                    type: 'text',
                    placeholder: this.newAttributeName
                });
                this.newAttributeName = '';
                this.newAttributeValue = '';
            }
        },
        removeAttributeField(index) {
            this.attributeFields.splice(index, 1);
        },
        handleFileUpload(event) {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.images.push({ file: files[i], preview: e.target.result });
                    if (i === 0) {
                        this.imagePreview = e.target.result;
                    }
                };
                reader.readAsDataURL(files[i]);
            }
        },
        removeImage(index) {
            this.images.splice(index, 1);
            if (this.images.length > 0) {
                this.imagePreview = this.images[0].preview;
            } else {
                this.imagePreview = null;
            }
        },
        setPreviewImage(index) {
            this.imagePreview = this.images[index].preview;
        },
        async fetchCategorias() {
            try {
                const response = await axios.get('/api/categoria/all');
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async fetchServicios() {
            try {
                const response = await axios.get('/api/servicio/all');
                this.servicios = response.data;
                this.filterServicios();
            } catch (error) {
                console.error('Error fetching servicios:', error);
            }
        },
        filterServicios() {
            if (this.selectedCategory === 'all') {
                this.filteredServicios = this.servicios;
            } else {
                this.filteredServicios = this.servicios.filter(servicio => servicio.CategoryID === parseInt(this.selectedCategory));
            }
        },
        filterContenido() {
            this.selectedProductDetails = this.servicios.find(servicio => servicio.ProductID === parseInt(this.selectedProduct)) || {};
        },
        async createContenidoServicio() {
            try {
                const attributes = this.attributeFields.map(field => ({
                    attributeName: field.label,
                    attributeValue: field.value
                }));

                const response = await axios.post('/api/contenido_servicio/create', {
                    productID: this.selectedProduct,
                    attributes: attributes,
                });

                // Si la inserción de las características fue exitosa, subir las imágenes
                if (response.status === 201) {
                    await this.uploadImages();
                    this.message = 'Contenido de servicio creado exitosamente!';
                    this.messageClass = 'alert alert-success';
                    this.resetForm();
                } else {
                    throw new Error('Error al crear las características del producto');
                }
            } catch (error) {
                console.error(error);
                this.message = 'Error al crear el contenido de servicio';
                this.messageClass = 'alert alert-danger';
            }
        },
        resetForm() {
            this.selectedProduct = '';
            this.selectedCategory = 'all';
            this.images = [];
            this.imagePreview = null;
            this.attributeFields = [];
            this.newAttributeName = '';
            this.newAttributeValue = '';
            this.selectedProductDetails = [];

        },
        async uploadImages() {
            try {
                const uploadedImages = [];
                for (let image of this.images) {
                    const formData = new FormData();
                    formData.append('image', image.file);
                    formData.append('productID', this.selectedProduct);
                    const response = await axios.post(`/api/upload-image/productos`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    uploadedImages.push(response.data.imageUrl);
                }
                return uploadedImages;
            } catch (error) {
                console.error('Error uploading images:', error);
                throw error;
            }
        },
    }
}
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}

.alert {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.img-preview {
    width: 100%;
    min-height: 210px;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    text-align: center;
}

.img-preview img {
    max-width: 100%;
    max-height: 210px;
}

.file-input {
    border: none;
    padding: 0;
}

.image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-item {
    position: relative;
}

.image-item img {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border: 1px solid #ccc;
}

.image-item a {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    padding: 2px 5px;
    cursor: pointer;
}

.attribute-container {
    display: flex;
    flex-wrap: wrap;
}

.col-6 {
    width: 50%;
    box-sizing: border-box;
    padding: 10px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.attribute-field {
    display: flex;
    align-items: center;
    gap: 10px;
}

.attribute-field input {
    flex: 1;
}

.attribute-field a {
    flex-shrink: 0;
    padding: 8px 12px;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
}

.attribute-input {
    display: flex;
    gap: 10px;
}

.attribute-input input {
    flex: 1;
}

.attribute-input a {
    flex-shrink: 0;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.attribute-input a:hover {
    background-color: #0056b3;
}
</style>
