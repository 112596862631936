<template>
    <div class="tab-pane fade active show" id="nav-item-info" role="tabpanel" aria-labelledby="nav-item-info-tab">
        <div class="step-one-content">
            <form class="default-form-style" @submit.prevent="createCategory">
                <div class="row">
                    <div class="col-12 row">
                        <div class="form-group col-5">
                            <label>Imagen de la categoría</label>
                            <div class="img-preview" v-if="imagePreview">
                                <img :src="imagePreview" alt="Vista previa de la imagen">
                            </div>
                            <div class="img-preview" v-else>
                                <span>Selecciona una imagen</span>
                            </div>
                            <input ref="categoryImage" type="file" @change="handleFileUpload" class="file-input">
                        </div>
                        <div class="col-7 column">
                            <div class="form-group">
                                <label>Nombre categoría</label>
                                <input v-model="categoryName" type="text" placeholder="Enter Title" required>
                            </div>

                            <div class="form-group">
                                <label>Url de la categoría</label>
                                <input v-model="categoryUrl" type="text" placeholder="Enter URL" required>
                            </div>
                            <div class="form-group">
                                <label>Precio minimo</label>
                                <input v-model="categoryPrice" type="number" placeholder="Enter Price" required>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label>Descripción</label>
                            <textarea v-model="categoryDescription" placeholder="Enter Description"></textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group button mb-0">
                            <button type="submit" class="btn">Crear Categoría</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CrearCategoria',
    data() {
        return {
            categoryName: '',
            categoryUrl: '',
            categoryImage: null,
            categoryDescription: '',
            categoryPrice: '',
            imagePreview: null,
            // status: '',
            // publico: '',
        }
    },
    methods: {
        handleFileUpload(event) {
            this.categoryImage = event.target.files[0];
            this.imagePreview = URL.createObjectURL(this.categoryImage);
        },
        async createCategory() {
            try {
                // Subir imagen al servidor del subdominio
                let formData = new FormData();
                formData.append('image', this.categoryImage);

                const imageResponse = await axios.post('/api/upload-image/categories', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                const imageUrl = imageResponse.data.imageUrl;

                // Crear categoría en la API principal
                const response = await axios.post('/api/categoria/create', {
                    NameCategory: this.categoryName,
                    urlCategories: "/" + this.categoryUrl,
                    urlImg: "/" + imageUrl,
                    DescriptionCategory: this.categoryDescription,
                    minPrices: this.categoryPrice
                    // status: this.status,
                    // publico: this.publico
                });

                alert('Categoría creada exitosamente!' + response);
                this.resetForm();
            } catch (error) {
                console.error(error);
                alert('Error al crear la categoría');
            }
        },
        resetForm() {
            this.categoryName = '';
            this.categoryUrl = '';
            this.categoryImage = null;
            this.categoryDescription = '';
            this.categoryPrice = '';
            this.imagePreview = null;
            // this.status = "";
            // this.publico = "";
            this.$refs.categoryImage.value = null;
        }
    }
}
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}

.img-preview {
    width: 100%;
    min-height: 210px;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    text-align: center;
}

.img-preview img {
    max-width: 100%;
    max-height: 210px;
}

.file-input {
    border: none;
    padding: 0;
}
</style>
