import { createRouter, createWebHistory } from "vue-router";
import Perfil from "../views/Perfil.vue";
import About from "../views/About.vue";
import Login from "../views/Login.vue";
import templateCategoria from "../views/categorias/template.vue";
import templateServicio from "../views/servicios/template.vue";
import templateContenidoServicios from "../views/contenidoServicios/template.vue";

const routes = [
  {
    path: "/",
    name: "PerfilView",
    component: Perfil,
    meta: { requiresAuth: true },
  },
  {
    path: "/categoria",
    name: "templateCategoria",
    component: templateCategoria,
    meta: { requiresAuth: true },
  },
  {
    path: "/productos",
    name: "templateServicio",
    component: templateServicio,
    meta: { requiresAuth: true },
  },
  {
    path: "/contenido-servicio",
    name: "templateContenidoServicios",
    component: templateContenidoServicios,
    meta: { requiresAuth: true },
  },
  {
    path: "/perfil",
    name: "PerfilView",
    component: Perfil,
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "AboutView",
    component: About,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "LoginView",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("tokenAccess");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      next();
    } else {
      next({ name: "LoginView" });
    }
  } else {
    next();
  }
});

export default router;
