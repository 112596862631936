// src/axios.js
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.baseURL = "https://api.todografico.shop";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "tokenAccess"
)}`;

export default axios;
