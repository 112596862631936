<!-- src/App.vue -->
<template>
  <div id="app">
    <div class="dashboard">

      <div class="row">
        <SidebarView v-if="showSidebar" />

        <ContentView v-if="showSidebar" />
        <router-view v-if="!showSidebar" />
      </div>
    </div>
  </div>
</template>


<script>
import SidebarView from './views/components/Sidebar.vue';
import ContentView from './views/components/Content.vue';

import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'App',
  components: {
    SidebarView,
    ContentView
  },
  setup() {
    const route = useRoute();
    const showSidebar = computed(() => route.path !== '/login');
    return {
      showSidebar
    };
  }
};
</script>
